<script>
import childrenNames from '@/mixins/childrenNames';
import {hasEditPermission,currentRoleLevelName,trialActiveForClients} from '@/mixins/roleLevelPermissions';

export default {
	mixins: [childrenNames],
	props: ['agreementData','hideForEdit','itemIndexToShow','forceAddNewItem'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-property') 
		};
	},
	computed: {
		accounts() {
			return this.agreementData.registeredEducationSavingsAccounts;
			// return this.agreementData.registeredEducationSavingsAccounts = this.agreementData.registeredEducationSavingsAccounts.map((resp)=>{
			// 	return resp.hasOwnProperty('additionalClausesToRecitals') ? resp  : {...resp, additionalClausesToRecitals: []};
			// });	
		},
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
		}
	},
	methods: {
		newAccount() {
			return {
				ageLimit: 35,
				exclusions: [],
				beneficiaries: [],
				additionalClauses: [],
				additionalClausesToRecitals: []
			};
		},
	},
	mounted(){
		for (let i = 0; i < this.agreementData.registeredEducationSavingsAccounts.length; i++) {
			const resp = this.agreementData.registeredEducationSavingsAccounts[i];
			if (!resp.hasOwnProperty('additionalClausesToRecitals')) {
				this.$set(resp, 'additionalClausesToRecitals', []);
			}
		}	
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Registered Education Savings Accounts</h4>

		<list-item
			v-slot="{ item: account, index: index }"
			v-model="accounts"
			:object-prototype="newAccount"
			:testid="'RESP-'"
			:hideAddBtn=hideForEdit
			:isEditView=hideForEdit
			:itemIndexToShow=itemIndexToShow
			:forceAddNewItem=forceAddNewItem
			:disabled="isViewMode"
		>
		<template v-if="!forceAddNewItem || (forceAddNewItem && index == (accounts.length-1))">
			<input-group
				v-model="account.name"
				class="mb-2"
				input-cols="8"
				label="Name of Financial Institution"
				reset-undefined
				:testid="'RESP-institution-name-'+index"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="account.lastFourDigits"
				class="my-3"
				reset-undefined
				input-cols="4"
				label="Last four digits of the account"
				placeholder="Digits (Optional)"
				:testid="'RESP-last-four-digits-'+index"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="my-3"
				:condition="agreementData.children.length > 1"
			>

				<p v-if="clientUserAgreement">
					You have more than one eligible child who could
					benefit from the RESP.
				</p>

				<p v-else>
					The parties have more than one eligible child who could
					benefit from the RESP.
				</p>

				<check-group
					v-model="account.beneficiaries"
					class="my-3"
					:agreement-data="agreementData"
					input-cols="4"
					label="Which children benefit from the RESP?"
					reset-undefined
					:options="childrenNames"
					:testid="'RESP-benefiting-children-'+index"
					:id="'RESP-benefiting-children-'+index"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<input-group
				v-model="account.balance"
				reset-undefined
				class="my-3"
				dollar
				label="RESP balance as of date of separation/date of agreement"
				:testid="'RESP-balance-'+index"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="account.ownership"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				label="Whose name is the RESP registered under?"
				reset-undefined
				partner
				select
				:options="['Joint']"
				:testid="'RESP-registered-name-'+index"
				:length="'long'"
				:disabled="isViewMode"
			/>
			<template v-if="!clientUserAgreement">
				<radio-group
					v-model="account.plan"
					class="my-2"
					input-cols="4"
					label="What will the owner do with the RESP upon separation?"
					reset-undefined
					select
					:options="[
						{ text: 'Keep', value: 'keep' },
						{ text: 'Transfer', value: 'transfer' },
					]"
					:testid="'RESP-upon-seperation-'+index"
					:length="'long'"
					:disabled="isViewMode"
				/>

				<conditional-group
					class="my-3"
					:condition="account.plan === 'transfer'"
				>
					<conditional-group
						class="mb-3"
						:condition="account.ownership === 'Joint'"
					>
						<radio-group
							v-model="account.recipient"
							class="my-3"
							:agreement-data="agreementData"
							input-cols="4"
							label="Who will keep the account?"
							reset-undefined
							partner
							select
							:testid="'RESP-account-keeper-'+index"
							:length="'long'"
							:disabled="isViewMode"
						/>
					</conditional-group>

					<radio-group
						v-model="account.amount"
						class="my-2"
						input-cols="9"
						label="What amount will be transferred?"
						reset-undefined
						select
						:options="[
							{ text: '50% of the account', value: 'half' },
							{
								text: 'The amount sufficient to equalize between the parties',
								value: 'equalize',
							},
							{ text: 'A specific amount', value: 'specific' },
						]"
						:testid="'RESP-amount-transferred-'+index"
						:length="'long'"
						:disabled="isViewMode"
					/>

					<conditional-group
						class="my-3"
						:condition="account.amount === 'specific'"
					>
						<input-group
							v-model="account.dollarAmount"
							reset-undefined
							class="my-3"
							dollar
							label="Specified amount"
							:testid="'RESP-specified-amount-'+index"
							:disabled="isViewMode"
						/>
					</conditional-group>
				</conditional-group>

				<p>
					If a child has not used the full amount of an RESP by the time
					the child has reached a particular age, the parties are free to
					collapse the RESP.
				</p>
				<input-group
					v-model="account.ageLimit"
					reset-undefined
					class="my-3"
					input-cols="2"
					label="What age would you like to set?"
					:testid="'RESP-age-limit-'+index"
					:disabled="isViewMode"
				/>

				<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="account.additionalClausesToRecitals" :testid="'RESP-add-clause-to-recitals-'+index+'-'"
					:disabled="isViewMode"/>
				<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="account.additionalClauses" :testid="'RESP-add-clause-'+index+'-'"
					:disabled="isViewMode"/>
			</template>
			</template>
		</list-item>
	</b-card>
</template>
