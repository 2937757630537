<script>
import childrenNames from '@/mixins/childrenNames';
import {hasEditPermission,currentRoleLevelName,trialActiveForClients} from '@/mixins/roleLevelPermissions';

export default {
	mixins: [childrenNames],
	props: ['agreementData','hideForEdit','itemIndexToShow','forceAddNewItem'],
	computed: {
		accounts() {
			return this.agreementData.pensions;
			// return this.agreementData.pensions = this.agreementData.pensions.map((pension)=>{
			// 	return pension.hasOwnProperty('additionalClausesToRecitals') ? pension  : {...pension, additionalClausesToRecitals: []};
			// });		
		},
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
		}
	},
	methods: {
		newAccount() {
			return {
				exclusions: [],
				additionalClauses: [],
				additionalClausesToRecitals:[]
			};
		},
	},
	data(){
		return{
			// toolTipForDisabledField : this.hideForEdit ? 'This value can be only changed from the builder. Please edit it from the buider page' : '',
			isViewMode : !hasEditPermission('section-property') 
		}
	},
	mounted(){
		for (let i = 0; i < this.agreementData.pensions.length; i++) {
			const pension = this.agreementData.pensions[i];
			if (!pension.hasOwnProperty('additionalClausesToRecitals')) {
				this.$set(pension, 'additionalClausesToRecitals', []);
			}
		}
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Pensions</h4>

		<list-item
			v-slot="{ item: account, index: index }"
			v-model="accounts"
			:object-prototype="newAccount"
			:testid="'pension-'"
			:hideAddBtn=hideForEdit
			:isEditView=hideForEdit
			:itemIndexToShow=itemIndexToShow
			:forceAddNewItem=forceAddNewItem
			:disabled="isViewMode"
		>
		<template v-if="!forceAddNewItem || (forceAddNewItem && index == (accounts.length-1))">
			<input-group
				v-model="account.name"
				class="mb-2"
				input-cols="8"
				label="Name of pension"
				reset-undefined
				placeholder="ie: Public Service Pension"
				:testid="'pension-name-'+index"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="account.ownership"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				label="How is the pension owned?"
				reset-undefined
				partner
				select
				:testid="'pension-ownership-'+index"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="account.planType"
				class="my-2"
				input-cols="4"
				label="What type of pension plan?"
				reset-undefined
				select
				:options="[
					{ text: 'Extraprovincial', value: 'extraprovincial' },
					{ text: 'Local', value: 'local' },
				]"
				:testid="'pension-plan-type-'+index"
				:disabled="isViewMode"
			/>
		    <template v-if="!clientUserAgreement">
			<yes-no-choice
				v-model="account.partiesAssignValue"
				reset-undefined
				label="Do the parties wish to assign a value to the pension?"
				class="my-3"
				:testid="'pension-parties-assign-value-'+index"
				:disabled="isViewMode"
			/>

				<conditional-group
					class="my-3"
					:condition="account.partiesAssignValue"
					:testid = "'pension-conditions-'+index"
				>
				
				<yes-no-choice
					v-model="account.obtainedValuation"
					reset-undefined
					label="Did the parties obtain a valuation of the pension?"
					class="my-3"
					:testid="'pension-obtained-valuation-'+index"
					:disabled="isViewMode"
				/>

					<input-group
						v-model="account.value"
						reset-undefined
						class="my-3"
						dollar
						label="What is the value of the pension?"
						:testid="'pension-value-'+index"
						:disabled="isViewMode"
					/>

					<radio-group
					v-model="account.orderOfTax"
					class="my-2"
					input-cols="4"
					label="Is this a before-tax or after-tax value?"
						reset-undefined
					select
					:options="[
						{ text: 'before-tax value', value: 'before-tax' },
						{ text: 'after-tax value', value: 'after-tax' },
					]"
					:testid="'order-of-tax-'+index"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<radio-group
				v-model="account.plan"
				class="my-3"
				input-cols="10"
				label="What will the owner do with the pension upon separation?"
				reset-undefined
				select
				:options="[
					{
						text: 'Owner will keep, and the other party will waive their interest',
						value: 'keep',
					},
					{
						text: 'Divide, in accordance with the Family Law Act',
						value: 'divide',
					},
				]"
				:testid="'pension-upon-separation-'+index"
				:length="'long'"
				:disabled="isViewMode"
			/>

				<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="account.additionalClausesToRecitals" :testid="'pension-add-clause-to-recitals-'+index+'-'" :disabled="isViewMode" />
				<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="account.additionalClauses" :testid="'pension-add-clause-'+index+'-'" :disabled="isViewMode" />
			</template>
		</template>
		</list-item>
	</b-card>
</template>
