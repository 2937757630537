import { mapState } from 'vuex';
import {
	accounts,
	allProperty,
	allPropertyExclusions,
	businesses,
	liabilities,
	pensions,
	properties,
	registeredAccounts,
	vehicles,
	onlyBusiness,
	onlyCorporation,
	onlyRESP,
	onlyRRSPWithDiscounts,
	onlyRRSPWithoutDiscounts,
	onlyAfterTaxPensions,
	onlyBeforeTaxPensions,
	otherProperties

} from '@/lib/property';

export default {
  computed: {
    ...mapState(['agreement']),
    liabilities: {
      cache: false,
      get() {
        return liabilities(this.agreement);
      }
    },
    vehicles: {
      cache: false,
      get() {
        return vehicles(this.agreement);
      }
    },
    property: {
      cache: false,
      get() {
        return properties(this.agreement);
      }
    },
    accounts: {
      cache: false,
      get() {
        return accounts(this.agreement);
      }
    },
    businesses: {
      cache: false,
      get() {
        return businesses(this.agreement);
      }
    },
    onlyBusiness: {
      cache: false,
      get() {
        return onlyBusiness(this.agreement);
      }
    },
    onlyCorporation: {
      cache: false,
      get() {
        return onlyCorporation(this.agreement);
      }
    },
    pensions: {
      cache: false,
      get() {
        return pensions(this.agreement);
      }
    },
    registeredAccounts: {
      cache: false,
      get() {
        return registeredAccounts(this.agreement);
      }
    },
    onlyRESP: {
      cache: false,
      get() {
        return onlyRESP(this.agreement);
      }
    },
    onlyRRSPWithDiscounts: {
      cache: false,
      get() {
        return onlyRRSPWithDiscounts(this.agreement);
      }
    },
    onlyAfterTaxPensions: {
      cache: false,
      get() {
        return onlyAfterTaxPensions(this.agreement);
      }
    },
    onlyBeforeTaxPensions: {
      cache: false,
      get() {
        return onlyBeforeTaxPensions(this.agreement);
      }
    },
    onlyRRSPWithoutDiscounts: {
      cache: false,
      get() {
        return onlyRRSPWithoutDiscounts(this.agreement);
      }
    },
    allProperty: {
      cache: false,
      get() {
        return allProperty(this.agreement);
      }
    },
    allPropertyExclusions: {
      cache: false,
      get() {
        // includes liabilities
        return allPropertyExclusions(this.agreement);
      }
    },
    otherProperties: {
      cache: false,
      get() {
        return otherProperties(this.agreement);
      }
    },
  },
};
